"use client";

import React, { createContext, useState } from "react";
import { DictionaryType } from "@utils/Localization/getDictionary";
import { LocaleCode } from "@utils/Country/countryEnums";

export interface LocalizationInterface {
  dictionary: DictionaryType;
  locale: LocaleCode;
  translate: (key: keyof DictionaryType) => string;
}

export const LocalizationContext = createContext<LocalizationInterface>({} as LocalizationInterface);

export const LocalizationProvider = ({
  children,
  dictionary,
  locale
}: {
  children: React.ReactNode;
  dictionary: DictionaryType;
  locale: LocaleCode;
}) => {
  const [dictionaryData] = useState<DictionaryType>(dictionary);

  const translate = (string: keyof DictionaryType): string => {
    const translatedString = dictionaryData[string];
    if (!translatedString) {
      return string;
    } else {
      return translatedString;
    }
  };

  return (
    <LocalizationContext.Provider
      value={{
        dictionary: dictionaryData,
        locale,
        translate
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
