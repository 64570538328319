export enum CurrencyCode {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GGP = "GGP",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  IMP = "IMP",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JEP = "JEP",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRU = "MRU",
  MTL = "MTL",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STN = "STN",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VES = "VES",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XCD = "XCD",
  XDR = "XDR",
  XOF = "XOF",
  XPF = "XPF",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL"
}

export enum DomainExtension {
  Albania = "al",
  Andorra = "ad",
  Armenia = "am",
  Austria = "at",
  Azerbaijan = "az",
  Belarus = "by",
  Belgium = "be",
  BosniaAndHerzegovina = "ba",
  Bulgaria = "bg",
  Croatia = "hr",
  Cyprus = "cy",
  CzechRepublic = "cz",
  Denmark = "dk",
  Estonia = "ee",
  Finland = "fi",
  France = "fr",
  Georgia = "ge",
  Germany = "de",
  Greece = "gr",
  Hungary = "hu",
  Iceland = "is",
  Ireland = "ie",
  Italy = "it",
  Kazakhstan = "kz",
  Kosovo = "xk",
  Latvia = "lv",
  Liechtenstein = "li",
  Lithuania = "lt",
  Luxembourg = "lu",
  Macedonia = "mk",
  Malta = "mt",
  Moldova = "md",
  Monaco = "mc",
  Montenegro = "me",
  Netherlands = "nl",
  NorthMacedonia = "mk",
  Norway = "no",
  Poland = "pl",
  Portugal = "pt",
  Romania = "ro",
  Russia = "ru",
  SanMarino = "sm",
  Serbia = "rs",
  Slovakia = "sk",
  Slovenia = "si",
  Spain = "es",
  Sweden = "se",
  Switzerland = "ch",
  Turkey = "tr",
  Ukraine = "ua",
  UnitedKingdom = "uk",
  VaticanCity = "va"
}

export enum LocaleCode {
  Albania = "sq",
  Andorra = "ca",
  Armenia = "hy",
  Austria = "de-at",
  Azerbaijan = "az",
  Belarus = "be",
  Belgium = "nl-be",
  BosniaAndHerzegovina = "bs",
  Bulgaria = "bg",
  Croatia = "hr",
  Cyprus = "el-cy",
  CzechRepublic = "cs",
  Denmark = "da",
  Estonia = "et",
  Finland = "fi",
  France = "fr",
  Georgia = "ka",
  Germany = "de",
  Greece = "el",
  Hungary = "hu",
  Iceland = "is",
  Ireland = "en-ie",
  Italy = "it",
  Kazakhstan = "kk",
  Kosovo = "sq-xk",
  Latvia = "lv",
  Liechtenstein = "de-li",
  Lithuania = "lt",
  Luxembourg = "lb",
  Macedonia = "mk",
  Malta = "mt",
  Moldova = "ro-md",
  Monaco = "fr-mc",
  Montenegro = "sr-me",
  Netherlands = "nl",
  NorthMacedonia = "mk",
  Norway = "nb",
  Poland = "pl",
  Portugal = "pt",
  Romania = "ro",
  Russia = "ru",
  SanMarino = "it-sm",
  Serbia = "sr",
  Slovakia = "sk",
  Slovenia = "sl",
  Spain = "es",
  Sweden = "sv",
  Switzerland = "de-ch",
  Turkey = "tr",
  Ukraine = "uk",
  UnitedKingdom = "en",
  VaticanCity = "it-va"
}

export enum CountryCode {
  Albania = "AL",
  Andorra = "AD",
  Armenia = "AM",
  Austria = "AT",
  Azerbaijan = "AZ",
  Belarus = "BY",
  Belgium = "BE",
  BosniaAndHerzegovina = "BA",
  Bulgaria = "BG",
  Croatia = "HR",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Estonia = "EE",
  Finland = "FI",
  France = "FR",
  Georgia = "GE",
  Germany = "DE",
  Greece = "GR",
  Hungary = "HU",
  Iceland = "IS",
  Ireland = "IE",
  Italy = "IT",
  Kazakhstan = "KZ",
  Kosovo = "XK",
  Latvia = "LV",
  Liechtenstein = "LI",
  Lithuania = "LT",
  Luxembourg = "LU",
  Macedonia = "MK",
  Malta = "MT",
  Moldova = "MD",
  Monaco = "MC",
  Montenegro = "ME",
  Netherlands = "NL",
  NorthMacedonia = "MK",
  Norway = "NO",
  Poland = "PL",
  Portugal = "PT",
  Romania = "RO",
  Russia = "RU",
  SanMarino = "SM",
  Serbia = "RS",
  Slovakia = "SK",
  Slovenia = "SI",
  Spain = "ES",
  Sweden = "SE",
  Switzerland = "CH",
  Turkey = "TR",
  Ukraine = "UA",
  UnitedKingdom = "GB",
  VaticanCity = "VA"
}

export enum LanguageName {
  Albania = "Albanian",
  Andorra = "Catalan",
  Armenia = "Armenian",
  Austria = "German",
  Azerbaijan = "Azerbaijani",
  Belarus = "Belarusian",
  Belgium = "Dutch",
  BosniaAndHerzegovina = "Bosnian",
  Bulgaria = "Bulgarian",
  Croatia = "Croatian",
  Cyprus = "Greek",
  CzechRepublic = "Czech",
  Denmark = "Danish",
  Estonia = "Estonian",
  Finland = "Finnish",
  France = "French",
  Georgia = "Georgian",
  Germany = "German",
  Greece = "Greek",
  Hungary = "Hungarian",
  Iceland = "Icelandic",
  Ireland = "Irish",
  Italy = "Italian",
  Kazakhstan = "Kazakh",
  Kosovo = "Albanian",
  Latvia = "Latvian",
  Liechtenstein = "German",
  Lithuania = "Lithuanian",
  Luxembourg = "Luxembourgish",
  Macedonia = "Macedonian",
  Malta = "Maltese",
  Moldova = "Romanian",
  Monaco = "French",
  Montenegro = "Montenegrin",
  Netherlands = "Dutch",
  NorthMacedonia = "Macedonian",
  Norway = "Norwegian",
  Poland = "Polish",
  Portugal = "Portuguese",
  Romania = "Romanian",
  Russia = "Russian",
  SanMarino = "Italian",
  Serbia = "Serbian",
  Slovakia = "Slovak",
  Slovenia = "Slovenian",
  Spain = "Spanish",
  Sweden = "Swedish",
  Switzerland = "German",
  Turkey = "Turkish",
  Ukraine = "Ukrainian",
  UnitedKingdom = "English",
  VaticanCity = "Italian"
}

export enum CountryName {
  Albania = "Albania",
  Andorra = "Andorra",
  Armenia = "Armenia",
  Austria = "Austria",
  Azerbaijan = "Azerbaijan",
  Belarus = "Belarus",
  Belgium = "Belgium",
  BosniaAndHerzegovina = "Bosnia and Herzegovina",
  Bulgaria = "Bulgaria",
  Croatia = "Croatia",
  Cyprus = "Cyprus",
  CzechRepublic = "Czech Republic",
  Denmark = "Denmark",
  Estonia = "Estonia",
  Finland = "Finland",
  France = "France",
  Georgia = "Georgia",
  Germany = "Germany",
  Greece = "Greece",
  Hungary = "Hungary",
  Iceland = "Iceland",
  Ireland = "Ireland",
  Italy = "Italy",
  Kazakhstan = "Kazakhstan",
  Kosovo = "Kosovo",
  Latvia = "Latvia",
  Liechtenstein = "Liechtenstein",
  Lithuania = "Lithuania",
  Luxembourg = "Luxembourg",
  Macedonia = "Macedonia",
  Malta = "Malta",
  Moldova = "Moldova",
  Monaco = "Monaco",
  Montenegro = "Montenegro",
  Netherlands = "Netherlands",
  NorthMacedonia = "North Macedonia",
  Norway = "Norway",
  Poland = "Poland",
  Portugal = "Portugal",
  Romania = "Romania",
  Russia = "Russia",
  SanMarino = "San Marino",
  Serbia = "Serbia",
  Slovakia = "Slovakia",
  Slovenia = "Slovenia",
  Spain = "Spain",
  Sweden = "Sweden",
  Switzerland = "Switzerland",
  Turkey = "Turkey",
  Ukraine = "Ukraine",
  UnitedKingdom = "United Kingdom",
  VaticanCity = "Vatican City"
}

export enum CountryNameLocalized {
  Albania = "Shqipëri",
  Andorra = "Andorra",
  Armenia = "Հայաստան",
  Austria = "Österreich",
  Azerbaijan = "Azərbaycan",
  Belarus = "Беларусь",
  Belgium = "België",
  BosniaAndHerzegovina = "Bosna i Hercegovina",
  Bulgaria = "България",
  Croatia = "Hrvatska",
  Cyprus = "Κύπρος",
  CzechRepublic = "Česká republika",
  Denmark = "Danmark",
  Estonia = "Eesti",
  Finland = "Suomi",
  France = "France",
  Georgia = "საქართველო",
  Germany = "Deutschland",
  Greece = "Ελλάδα",
  Hungary = "Magyarország",
  Iceland = "Ísland",
  Ireland = "Éire",
  Italy = "Italia",
  Kazakhstan = "Қазақстан",
  Kosovo = "Kosovë",
  Latvia = "Latvija",
  Liechtenstein = "Liechtenstein",
  Lithuania = "Lietuva",
  Luxembourg = "Lëtzebuerg",
  Macedonia = "Македонија",
  Malta = "Malta",
  Moldova = "Moldova",
  Monaco = "Monaco",
  Montenegro = "Crna Gora",
  Netherlands = "Nederland",
  NorthMacedonia = "Северна Македонија",
  Norway = "Norge",
  Poland = "Polska",
  Portugal = "Portugal",
  Romania = "România",
  Russia = "Россия",
  SanMarino = "San Marino",
  Serbia = "Србија",
  Slovakia = "Slovensko",
  Slovenia = "Slovenija",
  Spain = "España",
  Sweden = "Sverige",
  Switzerland = "Schweiz",
  Turkey = "Türkiye",
  Ukraine = "Україна",
  UnitedKingdom = "United Kingdom",
  VaticanCity = "Città del Vaticano"
}

export enum CurrencySide {
  Start = "Start",
  End = "End"
}
